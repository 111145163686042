<template>
<div style="background-color:white;">
    <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        margin="[10,10,10,10]"
        filename="Advisor Report"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        pdf-format="A4"
        pdf-orientation="portrait"
        pdf-content-width="auto"
        :manual-pagination="true"
        @progress="onProgress($event)"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf"
    >
    <section slot="pdf-content">
        <section class="pdf-item">
            <div class="col-md-12" style="background-color:white;">
                <div class="row" style="margin-top:20px">
                    <div class="col-md-6" v-if="isprint==0">
                        <h3 class="page-header" >
                        <i class="fa fa fa-list-alt animated bounceInDown show-info"></i>
                        Advisor
                        </h3>
                    </div>
                    <div class="col-md-12" v-if="isprint==1">
                        <h3 class="page-header text-center" >
                            <i class="fa fa fa-list-alt animated bounceInDown show-info"></i>
                            Advisor Report
                        </h3>
                    </div>
                    <div class="col-md-6 " v-if="isprint==0">
                        <div class="row" style="padding-top:18px;justify-content:flex-end;">
                            <div class="col-md-2" style="margin-right:25px">   
                                <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                            </div> 
                            <div class="col-md-2" style="margin-right:25px;">             
                                <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                            </div>
                        </div>
                </div>
                </div>
            </div>
       
            <div v-if="viewno==1">
                <div class=" panel pb-20" v-if="isprint==0">
                    <div class="col-md-12 flex-between-row">
                        <div v-if="loginusercheck(125)" class="col-md-3"><button class="btn btn-success" @click="openmessage()">Message<img src="../../assets/messagebird.gif" height="40" width="60"></button></div>
                        <div class="col-md-8 row">
                            <label class="search-icon"> <i class='bx bx-search-alt-2'></i></label>
                            <input  type="text" style="padding-left: 40px;" placeholder="Search Customer Id and Name" class="form-control form-cascade-control input-small search-txt" v-model="search">
                            <button @click="refresh()" style="margin-left:10px;" class="btn bg-success text-white username btnsearch"  type="button">Search</button>
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-xs pdf" v-if="clickpdf==false" @click="generateReport()"><span><i class='bx bxs-file-pdf' style="font-size:40px;"></i></span></button>
                            <span v-if="clickpdf" class="spinner"></span>
                        </div>
                    </div>
                </div>
                <div class="flex-start-row pb-20" v-if="isprint==0">
                    <div class="col-md-4 ">
                        <label><strong>From Date</strong></label>
                        <!-- <input type="date" class="form-control form-cascade-control" v-model="fromdate"> -->
                        <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="fromdate">
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <button type="button"
                                        class="date-picker"
                                        @click="togglePopover()">
                                        <i class='bx bx-calendar'></i>
                                    </button>
                                    <input :value="inputValue" @click="togglePopover()"
                                        class="form-control form-cascade-control input-small"
                                        placeholder="DD/MM/YYYY" readonly />
                                </div>
                            </template>
                        </vc-date-picker>
                    </div>
                    <div class="col-md-4 ">
                        <label><strong>To Date</strong></label>
                        <!-- <input type="date" class="form-control form-cascade-control" v-model="todate" @change="refresh()"> -->
                        <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="todate" @change="refresh()">
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <button type="button"
                                        class="date-picker"
                                        @click="togglePopover()">
                                        <i class='bx bx-calendar'></i>
                                    </button>
                                    <input :value="inputValue" @click="togglePopover()"
                                        class="form-control form-cascade-control input-small"
                                        placeholder="DD/MM/YYYY" readonly />
                                </div>
                            </template>
                        </vc-date-picker>
                    </div>
                    <div class="col-md-4">
                        <label><strong>Created</strong></label>
                        <select v-model="advisorcreatorid"  class="form-control form-cascade-control" @change="refresh()">
                            <option value="">Select</option>
                            <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12 flex-between-row detailborder">
                    <div class="col-md-4">
                        <span style="font-weight:700"><i class='bx bx-bookmarks'></i>  Total Advisors:</span> <span >{{totaladvisors}}</span>
                    </div>
                    <div class="col-md-4 text-center">
                        <span style="font-weight:700"><i class='bx bxs-no-entry' style="color:#f90606"></i>  Total DeActive:</span><span > {{deactiveadvisors}}</span>
                    </div>
                    <div class="col-md-4 text-right">
                        <span style="font-weight:700"><i class='bx bx-search-alt bx-tada' style="color:#121212" ></i>  Searched Advisors:</span><span class="amt">  {{advisorcount}} </span>
                    </div>
                </div>
                <div class="col-md-12 pt-20 flex-between-row" v-if="isprint==0">
                    <div class="col-md-8 row">
                        <div class="col-md-3"> <label for=""><strong>No. of Rec.</strong> </label> </div>
                        <div class="col-md-5">  
                            <select class="form-control col-mb-1 form-cascade-control" v-model="noofrec" id="rec" @change="refresh()">
                                <option value=10>10</option>
                                <option value=25>25</option>
                                <option value=50>50</option>
                                <option value=100>100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 table-responsive ">
                    <div class="  panel">
                        <div class="  panel-body" style="overflow-x:auto;padding-left:0px">
                            <table class="table tapble-bordered table-hover table-striped display">
                                <thead class="table-font" style="font-size:13px;">
                                    <tr>
                                        <th>#</th>
                                        <th class="visible-lg">Cust. Id</th>
                                        <th class="visible-lg" >NAME</th>
                                        <th v-if="isprint==0">Downline</th>
                                        <th >CONTACT</th>
                                        <th>Address</th>
                                        <th >Date Of Activate</th>
                                        <th>Created By</th>
                                        <th >Com. %</th>
                                        <th>Total Bal.</th>
                                        <th v-if="isprint==0">View Details</th>
                                        <th v-if="isprint==0">Pay Advisor</th>
                                        <th v-if="isprint==0">DeActive</th>
                                    </tr>
                                </thead>
                                <advisor-row v-for="(item,index) in advisors" v-bind:key="item.id" :item="item" :index="index" :currentpage="currentpage" :noofrec="noofrec" :isprint="isprint"></advisor-row>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12" style="margin-top: 20px" v-if="isprint==0">
                        <nav aria-label="Page navigation example" style="text-align: right !important">   
                            <paginate
                                style="margin: 0px !important; float: right"
                                :page-count="noofpages"
                                :click-handler="clickCallback"
                                :prev-text="'&laquo;'"
                                :next-text="'&raquo;'"
                                :container-class="'pagination'">
                            </paginate>
                        </nav>
                    </div>
                </div>
                <modal name="greetmessage" :width="500" :height="280"  draggable=".window-header">
                    <div class="col-md-12 pb-10 text-center">
                        <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Greet Advisors</h3>
                    </div>
                    <div class="col-md-12">
                        <label for="remark">Message</label>
                    </div>
                    <div class="col-md-12">
                        <textarea id="remark" disabled class="form-control " v-model="message"></textarea>
                    </div>
                    <div class="col-md-12 flex-between-row pt-20">
                        <button class="btn bg-danger text-white btn-sm chkcondition" style="margin-right:10px;" @click="close()">Cancel</button>
                        <button v-if="!sendsmstrue" class="btn bg-primary text-white btn-sm chkcondition" style="margin-right:10px;" @click="sendsms()">Send Message</button>
                        <span v-if="sendsmstrue" class="spinner"></span>
                    </div>
                </modal>
            </div>
        </section>
    </section>
    </vue-html2pdf>
    <!-- <down-line v-if="viewno==3"></down-line> -->
    <commission-details v-if="viewno==4"></commission-details>
    <customer-detail v-if="viewno==5"></customer-detail>
    <customer-form v-if="viewno==6"></customer-form>
</div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import Constants from '../../components/utilities/Constants.vue'
// import DownLine from './DownLine.vue'
import Paginate from "vuejs-paginate";
import { mapGetters } from 'vuex'
import CommissionDetails from './CommissionDetails.vue'
import AdvisorRow from './AdvisorRow.vue'
import CustomerDetail from '../customer/CustomerDetail.vue'
import CustomerForm from '../customer/CustomerForm.vue'
export default {
    mixins:[Constants],
    components:{CommissionDetails,AdvisorRow,CustomerDetail,CustomerForm,Paginate,VueHtml2pdf},
    data() {
        return {
            // ledgerbal:[],
            search:'',
            advisorcreatorid:'',
            fromdate:'',
            todate:'',
            remark:'',
            currentpage:1,
            noofrec:10,
            advisorcount:0,
            clickpdf:false,
            deactiveadvisors:0,
            totaladvisors:0,
            message:'Dear Team V.S Digiworld, App hamare bahumulya sadasya me se ek hai. Aap ka karya behad sarahniya hai kripya ise jaari rakhen, kisi bhi sahayta ke liye call kare 7565801550.',
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            sendsmstrue:false,
            isprint:0,
        }
    },
    computed:{
		...mapGetters([
		    'viewno','customers','employees','advisors'
		]),
        noofpages(){
            return Math.ceil(parseInt(this.advisorcount) / parseInt(this.noofrec))
        },
	},
    mounted(){
        this.refresh();
	},
    methods:{
        
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh();
        },
        openmessage(){
            this.$modal.show('greetmessage')
        },
        close(){
            this.$modal.hide('greetmessage')
        },
       back(){
            this.$store.commit('assignviewno',1)
        },
        // processledgerResponse(data){
        //     this.ledgerbal=data
        // },
        refresh(){
            this.$store.dispatch('fetchemployees')
            this.$store.commit("assignloadingstatus",1)
            let param={search:this.search,advisorcreatorid:this.advisorcreatorid,
                fromdate:this.fromdate,todate:this.todate,currentpage:this.currentpage,noofrec:this.noofrec}
            
            this.$http.post('api/fetch/advisor/remainingbalance',param)
            .then((response) => this.processadvisorBalResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.$http.post('api/fetch/advisor/remainingbalance/count',param)
            .then((response) => this.processadvisorCountResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.$http.post('api/users/deactive/fetchdeadvisorcount')
            .then((response) => this.processadvisordeCountResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.$http.post('api/users/totaladvisor/count')
            .then((response) => this.processadvisortotalCountResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processadvisortotalCountResponse(data){
            this.totaladvisors=data
        },
        processadvisordeCountResponse(data){
            this.deactiveadvisors=data
        },
        processadvisorBalResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit('assignadvisors',data)
        },
        processadvisorCountResponse(data){
            this.advisorcount=data
        },
        generatepdf(){
          this.clickpdf=true
            window.axios({
                url: 'https://cn373vzw7i.execute-api.ap-south-1.amazonaws.com/vsdigiadvisorreport/advisorreport?search='+this.search+'&advisorcreatorid='+this.advisorcreatorid+'&fromdate='+this.fromdate+'&todate='+this.todate, // File URL Goes Here
                method: 'GET',
                responseType: 'blob',
            })
            .then(response=>{
                this.clickpdf=false
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            
            fileLink.setAttribute('download', 'file.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();
    
            });
        },
        sendsms(){
            this.sendsmstrue=true
            let param={message:this.message}
            this.$http.post('api/advisors/send/sms',param)
            .then((response) => this.processsms(response.data))
            .catch((err) => {
                console.log(err)
            }); 
        },
        
        processsms(data){
            if(data!=null){
                this.sendsmstrue=false
                alert(data.message)
                this.$modal.hide('greetmessage')
            }
        },
        startDownload(){
            this.$store.commit("assignloadingstatus",1)
        },
        finishDownload(){
            this.$store.commit("assignloadingstatus",0)
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        generateReport () {
            this.isprint=1
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(){
            this.$parent.generate=false
            this.isprint=0
        },
        
    }
}
</script>
<style scoped>
.search-icon{
    text-align: right;
    margin-top: 5px;
    position: absolute;
    left: 4%;
    font-size: 24px;
}
.search-txt{
    width: 60%;
}
.detailborder{
    padding: 15px;
    background-color: #b2ffb4;
}
</style>